import React from "react";
import PropTypes from "prop-types";

const HTMLContent = (props) => {
  return (
    <div
      className={props.className}
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};
HTMLContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

export default HTMLContent;
